/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.62.2/mode/javascript/javascript.min.js
 * - /npm/codemirror@5.62.2/mode/css/css.min.js
 * - /npm/codemirror@5.62.2/mode/htmlmixed/htmlmixed.min.js
 * - /npm/codemirror@5.62.2/mode/ruby/ruby.min.js
 * - /npm/codemirror@5.62.2/mode/go/go.min.js
 * - /npm/codemirror@5.62.2/mode/php/php.min.js
 * - /npm/codemirror@5.62.2/mode/clike/clike.min.js
 * - /npm/codemirror@5.62.2/mode/python/python.min.js
 * - /npm/codemirror@5.62.2/addon/selection/active-line.min.js
 * - /npm/codemirror@5.62.2/addon/selection/mark-selection.min.js
 * - /npm/codemirror@5.62.2/addon/selection/selection-pointer.min.js
 * - /npm/codemirror@5.62.2/addon/search/jump-to-line.min.js
 * - /npm/codemirror@5.62.2/addon/search/match-highlighter.min.js
 * - /npm/codemirror@5.62.2/addon/search/matchesonscrollbar.min.js
 * - /npm/codemirror@5.62.2/addon/search/search.min.js
 * - /npm/codemirror@5.62.2/addon/search/searchcursor.min.js
 * - /npm/codemirror@5.62.2/addon/edit/closebrackets.min.js
 * - /npm/codemirror@5.62.2/addon/edit/closetag.min.js
 * - /npm/codemirror@5.62.2/addon/edit/continuelist.min.js
 * - /npm/codemirror@5.62.2/addon/edit/matchtags.min.js
 * - /npm/codemirror@5.62.2/addon/edit/trailingspace.min.js
 * - /npm/codemirror@5.62.2/addon/edit/matchbrackets.min.js
 * - /npm/codemirror@5.62.2/addon/dialog/dialog.min.js
 * - /npm/codemirror@5.62.2/addon/fold/brace-fold.min.js
 * - /npm/codemirror@5.62.2/addon/fold/comment-fold.min.js
 * - /npm/codemirror@5.62.2/addon/fold/foldcode.min.js
 * - /npm/codemirror@5.62.2/addon/fold/foldgutter.min.js
 * - /npm/codemirror@5.62.2/addon/fold/indent-fold.min.js
 * - /npm/codemirror@5.62.2/addon/fold/markdown-fold.min.js
 * - /npm/codemirror@5.62.2/addon/fold/xml-fold.min.js
 * - /npm/codemirror@5.62.2/addon/mode/loadmode.min.js
 * - /npm/codemirror@5.62.2/addon/display/autorefresh.min.js
 * - /npm/codemirror@5.62.2/keymap/sublime.min.js
 * - /npm/codemirror@5.62.2/mode/xml/xml.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
